
.card {

    background-color: white;
    margin-bottom: 1rem;
    box-shadow: 0 1px 2px rgba(0,0,0,0.04);
    border:none;
    border-radius: $border-radius;
    display: block;

    &.bg-success, &.bg-primary, &.bg-info, &.bg-danger, &.bg-warning {

        .card-title, .card-subtitle {
            color:white;
        }
    }
}

a.card:hover {

    text-decoration:none;
}

.card-table .card-body {

    padding:0;

    .netbs-renderer, .basic-renderer, th {
        border:none !important;
    }
}

.card-header {

    padding:1.5rem 0 1rem 0;
    margin:0 1.5rem;
    background:transparent;

    &.no-divider {
        border-bottom:none;
    }

    .card-title {

        font-size:1.25rem;
        color:$gray-800;
        margin:0;
    }

    .card-subtitle {

        color:$gray-600;
        font-size:0.9rem;
        margin-top:0.75rem;
    }
}